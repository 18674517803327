import request from '@/utils/request';
import { AssessmentDocumentsItem } from '@/types/AssessmentDocumentItem';

const baseUri = '/staff/assessment_documents';

export const getDocuments = (params?: object) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const createDocument = (data?: object, params?: object) =>
    request({
        url: baseUri,
        method: 'POST',
        data,
        params,
    });

export const getConfig = (params?: object) =>
    request({
        url: `${baseUri}/config`,
        method: 'GET',
        params,
    });

export const setConfig = (data?: object) =>
    request({
        url: `${baseUri}/config`,
        method: 'PUT',
        data,
    });

export const resetConfig = (data?: object) =>
    request({
        url: `${baseUri}/config`,
        method: 'DELETE',
        data,
    });

export const getMonthlyReport = (params?: object) =>
    request({
        url: `${baseUri}/monthly_report`,
        method: 'GET',
        params,
    });

export const getDocument = (id: string, params?: object) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const approveDocument = (id: string) =>
    request({
        url: `${baseUri}/${id}/approve`,
        method: 'POST',
    });

export const disapproveDocument = (id: string) =>
    request({
        url: `${baseUri}/${id}/disapprove`,
        method: 'POST',
    });

export const createDocumentItem = (id: string, data?: object, params?: object) =>
    request({
        url: `${baseUri}/${id}/items`,
        method: 'POST',
        data,
        params,
    });

export const updateDocumentItem = (id: string, itemId: string, data?: object, params?: object) =>
    request({
        url: `${baseUri}/${id}/items/${itemId}`,
        method: 'PATCH',
        data,
        params,
    });

export const deleteDocumentItem = (id: string, itemId: string) =>
    request({
        url: `${baseUri}/${id}/items/${itemId}`,
        method: 'DELETE',
    });

export const changeTaxes = (data?: object): Promise<AssessmentDocumentsItem> =>
    request({
        url: `${baseUri}/taxes`,
        method: 'PUT',
        data,
    });

export const resetTaxes = (data?: object): Promise<AssessmentDocumentsItem> =>
    request({
        url: `${baseUri}/reset_taxes`,
        method: 'POST',
        data,
    });

export const changeDeposits = (data?: object): Promise<AssessmentDocumentsItem> =>
    request({
        url: `${baseUri}/deposits`,
        method: 'PUT',
        data,
    });
