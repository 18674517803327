<template>
    <div
        v-resize="handleResize"
        class="data-table"
        :class="{ 'with-summary': withSummary, 'is-editable': editable, 'data-table--fixed-header': fixedHeader, 'tree-design': enableTreeDesign }"
    >
        <el-table
            :key="tableKey"
            ref="table"
            v-loading="isLoading"
            :data="tableData"
            :class="{ 'no-hover-effects': disableHoverEffects, 'is-tree': !!$attrs['row-key'], 'is-expand-hidden': hideExpandButton, 'is-dragging': isDragging }"
            :row-class-name="getRowClass"
            :cell-class-name="getCellClass"
            :tree-props="treeProps"
            :load="load"
            v-bind="proxyProps"
            v-on="proxyListeners"
            @row-click="handleRowClick"
            @click.native.middle="handleMiddleClick"
        >
            <slot />

            <el-table-column
                v-if="!disableActions"
                prop="actions"
                :width="actionsColumnWidth"
                class-name="is-actions"
            >
                <template #header="{ $index, column }">
                    <el-button
                        v-if="changeColumns"
                        type="flat-secondary"
                        circle
                        size="mini"
                        title="Настройка списка"
                        @click="changeList"
                    >
                        <iconify-icon icon="fa6-solid:gear" />
                    </el-button>
                    <slot
                        name="row-actions-header"
                        :column="column"
                        :index="$index"
                    />
                </template>
                <template #default="{ $index, row }">
                    <slot
                        name="row-actions"
                        :row="row"
                        :index="$index"
                    />
                </template>
            </el-table-column>

            <template #append>
                <el-button
                    v-if="isShowLoadMoreButton"
                    :loading="isLoadingMore"
                    size="large"
                    class="data-table__load-more"
                    @click="handleLoadMore"
                >
                    Показать ещё
                </el-button>
            </template>
        </el-table>

        <ui-pagination
            v-if="pagination"
            ref="pagination"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :total="total"
            @current-change="handlePageChange"
        />

        <div
            v-if="$slots.footer"
            class="data-table__footer"
            :class="{ 'sticky-footer': stickyFooter }"
        >
            <div>
                <slot name="footer" />
            </div>
        </div>

        <table-settings-dialog
            v-if="changeColumns"
            :visible.sync="editDialogVisible"
            :columns="allColumns"
            :settings="tableSettings"
        />
    </div>
</template>

<script>
import { cloneDeep, pickBy, throttle, uniq } from 'lodash';
import { getClosestElementWithScroll } from '@/utils';
import { DEFAULT_PAGE_SIZE } from '@/config';
import WrapperMixin from '@/mixins/wrapper';
import Sortable from 'sortablejs';
import TableSettingsDialog from '@/components/TableSettingsDialog.vue';

const noop = () => undefined;

export default {
    name: 'UiDataTable',
    components: { TableSettingsDialog },

    mixins: [WrapperMixin],

    props: {
        data: {
            type: Array,
            required: true,
        },

        page: {
            type: Number,
            default: 1,
        },

        total: {
            type: Number,
            default: 0,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        fixedHeader: {
            type: Boolean,
            default: true,
        },

        clickable: {
            type: Boolean,
            default: false,
        },

        disableActions: {
            type: Boolean,
            default: false,
        },

        disableHoverEffects: {
            type: Boolean,
            default: false,
        },

        cellClassName: {
            type: [String, Function],
            default: '',
        },

        paginationQueryParam: {
            type: String,
            default: 'page',
        },

        paginationUseHistory: {
            type: Boolean,
            default: false,
        },

        isInfiniteScroll: {
            type: Boolean,
            default: false,
        },

        isLoadingMore: {
            type: Boolean,
            default: false,
        },

        treeProps: {
            type: Object,
            default: () => ({
                hasChildren: 'hasChildren',
                children: 'children',
            }),
        },

        pageSize: {
            type: Number,
            default: DEFAULT_PAGE_SIZE,
        },

        actionsColumnWidth: {
            type: [Number, String],
            default: 45,
        },

        hideExpandButton: {
            type: Boolean,
            default: false,
        },

        editable: {
            type: Boolean,
            default: false,
        },

        withSummary: {
            type: Boolean,
            default: false,
        },

        rowClassName: {
            type: [String, Function],
            default: '',
        },

        load: {
            type: Function,
            /**
             * @param {object} tree
             * @param {object} treeNode
             * @param {function} resolve
             * @returns {undefined}
             */
            default: noop,
        },

        // Иногда нужна сложная логика кликабельности ячеек и штатная возможность только мешается
        skipClickCheck: {
            type: Boolean,
            default: false,
        },

        stickyFooter: {
            type: Boolean,
            default: false,
        },

        enableDragging: {
            type: Boolean,
            default: false,
        },

        disabledDragItemClass: {
            type: String,
            default: 'ignore-elements',
        },

        enableTreeDesign: {
            type: Boolean,
            default: false,
        },

        parentChecker: {
            type: Function,
            default: ({ parent, dragged, newLevel }) => true,
        },

        pagination: {
            type: Boolean,
            default: true,
        },

        changeColumns: {
            type: Boolean,
            default: false,
        },

        beforeRowChangeChecker: {
            type: Function,
            default: ({ prevItem, nextItem, draggedItem }) => true,
        },
    },

    data () {
        return {
            scrollableElement: null,
            prevY: null,
            tableKey: 0,
            footerObserver: null,
            sortable: [],
            isDragging: false,
            rowsWrapper: null,
            oldX: null,
            treeData: null,
            dragInfo: {
                draggableEls: [],
                parent: null,
                nextItem: null,
                prevItem: null,
                level: 0,
                newIndex: 0,
                startIndex: 0,
            },
            hoverRowTimeout: null,
            editDialogVisible: false,
            flatTableData: [],
        };
    },

    computed: {
        tableData () {
            let rows;

            if (this.editable) {
                rows = this.data.map((item, index) => {
                    return {
                        ...cloneDeep(item),
                        index,
                    };
                });
            } else {
                rows = this.data;
            }

            if (this.withSummary && rows.length > 0) {
                rows.push({ isSummary: true });
            }

            return rows;
        },

        currentPage: {
            get () {
                return this.page;
            },

            set (value) {
                this.$emit('update:page', value);
            },
        },

        isLoading () {
            return this.loading && !this.isLoadingMore;
        },

        proxyListeners () {
            return pickBy(this.$listeners, (value, key) => key !== 'row-click');
        },

        shown () {
            if (this.isLoading) {
                return 0;
            }

            return Math.min(this.total, this.page * this.pageSize);
        },

        isShowLoadMoreButton () {
            const totalPages = Math.ceil((this.total > 0 ? this.total : 1) / this.pageSize);
            return this.isInfiniteScroll && (this.isLoadingMore || this.page < totalPages);
        },

        allColumns () {
            return this.$slots.default
                .filter(i => i?.componentOptions?.tag === 'ui-data-table-column')
                .map(i => {
                    return {
                        property: i.data.attrs.prop,
                        label: i.data.attrs.label,
                        defaultVisible: !i.data.attrs['default-visible'],
                    };
                })
                .filter(i => i.property);
        },

        tableSettings () {
            if (!this.changeColumns) {
                return [];
            }
            return this.$store.getters['tableSettings/list'].find(i => i.contextId === this.$route.name)?.settings;
        },
    },

    watch: {
        currentPage (value, oldValue) {
            const pageInQuery = parseInt(this.$route.query[this.paginationQueryParam] ?? 1);

            if (
                this.paginationUseHistory &&
                value !== oldValue &&
                parseInt(value) !== pageInQuery
            ) {
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        [this.paginationQueryParam]: value > 1 ? value : undefined,
                    },
                });
            }
        },

        isLoadingMore (value) {
            if (!value) {
                this.$nextTick(() => {
                    this.scrollableElement?.scrollTo({ top: this.prevY });
                });
            }
        },

        isDragging (value) {
            if (!value) {
                this.dragInfo = {
                    draggableEls: [],
                    parent: null,
                    nextItem: null,
                    prevItem: null,
                    level: 0,
                    newIndex: 0,
                    startIndex: 0,
                };
            }
        },

        data (value) {
            const flatTree = (item, level) => {
                const items = [{ ...item, level }];
                if (item.children) {
                    item.children.forEach(i => {
                        items.push(...flatTree(i, level + 1));
                    });
                }

                return items;
            };

            this.flatTableData = value.flatMap(i => flatTree(i, 0));

            if (this.enableDragging && value.length !== 0) {
                this.initDrag();
            }
        },

        enableDragging (value) {
            if (value) {
                this.initDrag();
            } else {
                this.destroyDrag();
            }
        },
    },

    created () {
        if (this.$store.getters['tableSettings/list'].length === 0 && this.changeColumns) {
            this.$store.dispatch('tableSettings/fetchList');
        }
    },

    mounted () {
        if (this.fixedHeader) {
            this.observeTableScrolling();
        }

        if (this.stickyFooter) {
            this.observeFooter();
        }

        if (this.enableDragging) {
            this.initDrag();
        }

        this.$watch(
            '$refs.table.layout.store._data.states.treeData',
            (value) => {
                this.treeData = value;
            },
            { deep: true },
        );
    },

    beforeDestroy () {
        this.footerObserver?.disconnect();

        if (this.enableDragging) {
            this.destroyDrag();
        }
    },

    methods: {
        toggleRowExpansion (row, expanded) {
            this.$refs.table.toggleRowExpansion(row, expanded);
        },

        // Так как el-table не умеет динамически обновлять данные по дочерним строкам мутим костыли...
        reloadRowChildren (row) {
            const childrenKey = this.treeProps?.children ?? 'children';
            const hasChildrenKey = this.treeProps?.hasChildren ?? 'hasChildren';
            delete row[hasChildrenKey];
            // TODO: Второй аргумент не прокидываем, возможны косяки, но вроде нигде не используем
            this.load(row, {}, data => {
                row[childrenKey] = data;
                ++this.tableKey;
                this.$nextTick(() => {
                    this.toggleRowExpansion(row, true);
                });
            });
        },

        getRowClass (args) {
            const classes = [];

            if (
                this.editable &&
                !args.row?.isSummary
            ) {
                classes.push('is-editable');
            }

            if (args.row?.isSummary) {
                classes.push('is-summary');
            }

            if (typeof this.rowClassName === 'function') {
                classes.push(this.rowClassName.call(null, args));
            } else if (this.rowClassName !== '') {
                classes.push(this.rowClassName);
            }

            if (this.enableTreeDesign && this.treeData) {
                const rowData = this.treeData[args.row?.id];
                if (rowData?.expanded && !rowData.children.some(i => this.hasChildExpanded(i))) {
                    classes.push('is-expanded');
                }

                const entry = Object.entries(this.treeData).find(([, i]) => i.children.includes(args.row?.id));
                if (
                    entry &&
                    entry[1].children.indexOf(args.row?.id) === entry[1].children.length - 1 &&
                    !rowData?.expanded
                ) {
                    const level = this.getUnderlineLevel(entry[0], entry[1].level);
                    classes.push('is-underline', `is-underline--${level}`);
                }
            }

            return uniq(classes).join(' ');
        },

        getCellClass (args) {
            const classes = [];

            if (this.clickable && !this.editable && !args.row?.isSummary) {
                classes.push('is-clickable');
            }

            if (this.$attrs['row-key'] && (args.column.type === 'expand' || args.columnIndex === 0)) {
                classes.push('has-expand');
            }

            if (typeof this.cellClassName === 'function') {
                classes.push(this.cellClassName.call(null, args));
            } else if (this.cellClassName !== '') {
                classes.push(this.cellClassName);
            }

            return uniq(classes).join(' ');
        },

        hasChildren (row) {
            return Boolean(row[this.treeProps.hasChildren || 'hasChildren']);
        },

        getRowLevel (el) {
            const list = [...el?.classList];
            const levelClass = list.find(i => i.startsWith('el-table__row--level'));
            return parseInt(levelClass?.split('-')?.at(-1) ?? 0);
        },

        selectChildren (element) {
            Sortable.utils.select(element);
            const level = this.getRowLevel(element);
            const rowEls = [...this.getElements()].slice(element.rowIndex + 1);

            for (const el of rowEls) {
                const elLevel = this.getRowLevel(el);

                if (elLevel <= level) {
                    break;
                }

                Sortable.utils.select(el);
            }
        },

        getElements () {
            return this.rowsWrapper.querySelectorAll('.el-table__row');
        },

        observeTableScrolling () {
            const observer = new MutationObserver(mutationList => {
                const m = mutationList.find(m => m?.attributeName === 'class');

                if (!m?.target) {
                    return;
                }

                const classList = ['is-scrolling-right', 'is-scrolling-middle'];
                this.$el.classList.toggle(
                    'data-table--fixed-header',
                    !classList.some(className => m.target.classList.contains(className)),
                );
            });
            observer.observe(
                this.$el.querySelector('.el-table__body-wrapper'),
                { attributes: true },
            );
        },

        observeFooter () {
            const observer = new MutationObserver(mutations => {
                for (const mutation of mutations) {
                    const addedFooter = Array.from(mutation.addedNodes)
                        // 1 - означает тип ноды Element (у него есть classList)
                        // https://developer.mozilla.org/en-US/docs/Web/API/Node#node.nodetype
                        .filter(node => node.nodeType === 1)
                        .find(node => node.classList.contains('data-table__footer'));

                    const removedFooter = Array.from(mutation.removedNodes)
                        .filter(node => node.nodeType === 1)
                        .find(node => node.classList.contains('data-table__footer'));

                    if (addedFooter) {
                        this.footerObserver = new IntersectionObserver(
                            ([e]) => e.target.classList.toggle('is-sticky', e.intersectionRatio < 1),
                            {
                                rootMargin: '0px 0px -20px 0px',
                                threshold: [1],
                            },
                        );
                        this.footerObserver.observe(addedFooter);
                    }

                    if (removedFooter) {
                        this.footerObserver?.unobserve(removedFooter);
                    }
                }
            });

            observer.observe(this.$el, { childList: true });
        },

        initDrag () {
            if (this.rowsWrapper) {
                this.destroyDrag();
            }

            if (this.$refs.table.$refs.fixedBodyWrapper) {
                this.rowsWrapper = this.$refs.table.$refs.fixedBodyWrapper.querySelector('tbody');
            } else {
                this.rowsWrapper = this.$refs.table.$el.querySelector('tbody');
            }

            // Спиздил часть кода из Sortablejs
            const Safari = navigator.userAgent.match(/safari/i) &&
                !navigator.userAgent.match(/chrome/i) &&
                !navigator.userAgent.match(/android/i);
            const supportPointer = 'PointerEvent' in window && !Safari;

            if (supportPointer) {
                this.rowsWrapper.addEventListener('pointerdown', this.handleMouseDown);
            } else {
                this.rowsWrapper.addEventListener('mousedown', this.handleMouseDown);
                this.rowsWrapper.addEventListener('touchstart', this.handleMouseDown);
            }

            this.rowsWrapper.addEventListener('mousemove', throttle(this.handleMouseMove, 300, { trailing: false }));

            this.$nextTick(() => {
                this.rowsWrapper.querySelectorAll('.el-table__row').forEach(tr => {
                    tr.addEventListener('mouseenter', this.handleMouseEnterRow);
                    tr.addEventListener('mouseleave', this.handleMouseLeaveRow);
                });
            });

            this.sortable = Sortable.get(this.rowsWrapper) ?? Sortable.create(this.rowsWrapper, {
                filter: `.${this.disabledDragItemClass}, .el-table__expand-icon`,
                forceFallback: true,
                multiDrag: true,
                animation: 150,
                invertSwap: true,
                invertedSwapThreshold: 1,
                onStart: ({ items, oldIndex }) => {
                    Object.assign(this.dragInfo, {
                        draggableEls: items,
                        parent: this.flatTableData[oldIndex].parent,
                        level: this.flatTableData[oldIndex].level,
                        newIndex: oldIndex,
                        startIndex: oldIndex
                    });

                    const draggedItems = this.flatTableData.splice(this.dragInfo.newIndex, items.length);
                    this.dragInfo.prevItem = this.getPrevItemInSameNode(oldIndex);
                    this.dragInfo.nextItem = this.getNextItemInSameNode(oldIndex);
                    this.dragInfo.draggedItems = draggedItems;
                    this.isDragging = true;
                },
                onChange: ({ newIndex }) => {
                    const prevItem = this.flatTableData[newIndex - 1];
                    const nextItem = this.flatTableData[newIndex];

                    let level = this.dragInfo.level;

                    if (newIndex !== 0) {
                        if (
                            this.treeData[prevItem?.id]?.expanded &&
                            nextItem.level === prevItem.level + 1 &&
                            this.parentChecker({
                                parent: prevItem,
                                dragged: this.dragInfo.draggedItems[0],
                                newLevel: nextItem.level
                            })
                        ) {
                            // Перетаскиваемый элемент - 1-й дочерний
                            level = nextItem.level;
                        } else if (
                            this.treeData[nextItem?.parentId]?.expanded &&
                            nextItem.level === prevItem.level &&
                            this.parentChecker({
                                parent: nextItem.parent,
                                dragged: this.dragInfo.draggedItems[0],
                                newLevel: nextItem.level
                            })
                        ) {
                            // Перетаскиваемый элемент - между дочерними
                            level = nextItem.level;
                        } else if (
                            this.treeData[prevItem?.parentId]?.expanded &&
                            nextItem.level !== prevItem.level &&
                            this.parentChecker({
                                parent: prevItem.parent,
                                dragged: this.dragInfo.draggedItems[0],
                                newLevel: prevItem.level
                            })
                        ) {
                            // Перетаскиваемый элемент - последний дочерний
                            level = prevItem.level;
                        }
                    }

                    this.dragInfo.level = level;
                    this.updateDraggableElLevel();
                    this.dragInfo.parent = this.flatTableData.findLast((row, index) => index < newIndex && row.level === this.dragInfo.level - 1);
                    this.dragInfo.prevItem = this.getPrevItemInSameNode(newIndex);
                    this.dragInfo.nextItem = this.getNextItemInSameNode(newIndex);
                    this.dragInfo.newIndex = newIndex;
                },
                onEnd: ({ newIndex }) => {
                    this.flatTableData.splice(newIndex, 0, ...this.dragInfo.draggedItems);

                    const isChanged = this.dragInfo.startIndex !== newIndex ||
                        this.dragInfo.draggedItems[0].parent?.id !== this.dragInfo.parent?.id;
                    const payload = {
                        after: this.dragInfo.prevItem,
                        before: this.dragInfo.nextItem,
                        parent: this.dragInfo.parent,
                        dragged: this.dragInfo.draggedItems[0]
                    };

                    this.isDragging = false;
                    this.$refs.table.$el.querySelectorAll('.el-table__row').forEach(el => {
                        el.classList.remove('hover-row');
                    });

                    if (!isChanged) {
                        return;
                    }

                    this.$emit('drag-end', payload);
                },
                onUnchoose: ({ items }) => {
                    items.forEach(i => {
                        Sortable.utils.deselect(i);
                    });
                },
                onMove: ({ dragged, related, willInsertAfter }) => {
                    const isTopDirection = dragged.rowIndex > related.rowIndex;
                    let nextItemIndex = related.rowIndex;
                    if (isTopDirection && willInsertAfter) {
                        nextItemIndex++;
                    } else if (!isTopDirection && !willInsertAfter) {
                        nextItemIndex--;
                    }
                    return this.beforeRowChangeChecker({
                        prevItem: this.flatTableData[nextItemIndex - 1],
                        nextItem: this.flatTableData[nextItemIndex],
                        draggedItem: this.dragInfo.draggedItems[0]
                    });
                },
                onFilter: ({ items }) => {
                    items.forEach(i => {
                        Sortable.utils.deselect(i);
                    });
                },
            });
        },

        getPrevItemInSameNode (currentIndex) {
            let prevItemInNode = null;

            for (let i = currentIndex - 1; i > 0; i--) {
                if (this.flatTableData[i].level < this.dragInfo.level) {
                    break;
                }

                if (this.flatTableData[i].level === this.dragInfo.level) {
                    prevItemInNode = this.flatTableData[i];
                    break;
                }
            }

            return prevItemInNode;
        },

        getNextItemInSameNode (currentIndex) {
            let nextItemInNode = null;

            for (let i = currentIndex; i < this.flatTableData.length; i++) {
                if (this.flatTableData[i].level < this.dragInfo.level) {
                    break;
                }

                if (this.flatTableData[i].level === this.dragInfo.level) {
                    nextItemInNode = this.flatTableData[i];
                    break;
                }
            }

            return nextItemInNode;
        },

        updateDraggableElLevel () {
            this.dragInfo.draggableEls[0].classList.remove(...[...this.dragInfo.draggableEls[0].classList].filter(i => i.startsWith('el-table__row--level')));
            this.dragInfo.draggableEls[0].classList.add(`el-table__row--level-${this.dragInfo.level}`);
        },

        destroyDrag () {
            Sortable.get(this.rowsWrapper)?.destroy();
            this.rowsWrapper.removeEventListener('mousedown', this.handleMouseDown);
            this.rowsWrapper.removeEventListener('pointerdown', this.handleMouseDown);
            this.rowsWrapper.removeEventListener('touchstart', this.handleMouseDown);
            this.rowsWrapper.removeEventListener('mousemove', this.handleMouseMove);
            this.rowsWrapper = null;
        },

        hasChildExpanded (key) {
            const rowData = this.treeData[key];
            if (!rowData) {
                return false;
            }

            if (rowData.expanded) {
                return true;
            } else {
                return rowData.children.some(i => this.hasChildExpanded(i));
            }
        },

        getUnderlineLevel (key, level) {
            const entry = Object.entries(this.treeData).find(([, value]) => value.children.includes(key));
            if (!entry || entry[1].children.indexOf(key) !== entry[1].children.length - 1) {
                return level;
            } else {
                return this.getUnderlineLevel(entry[0], entry[1].level);
            }
        },

        handleRowClick (row, column, event) {
            if (
                (!this.skipClickCheck && !this.clickable) ||
                column?.property === 'actions' ||
                column?.type === 'selection'
            ) {
                return;
            }

            if (event.shiftKey || event.metaKey) {
                if (row?.href) {
                    window.open(`${window.origin}${row.href}`, '_blank');
                }
            } else {
                this.$emit('row-click', row, column, event);
            }
        },

        handleMiddleClick (event) {
            // TODO когда перейдем на vue 3 отлетит к хуям
            const row = this.getRowByEvent(event);

            if (row?.href) {
                window.open(`${window.origin}${row.href}`, '_blank');
            }

            this.$emit('row-middle-click', row, event);
        },

        getRowByEvent (event) {
            const targetElement = event.target;
            const tableRowElement = targetElement.closest('.el-table__row');
            if (tableRowElement) {
                const rowComponent = tableRowElement.__vue__;
                if (rowComponent && rowComponent.row) {
                    return rowComponent.row;
                }
            }
            return null;
        },

        handlePageChange () {
            this.$refs.table.$el.scrollIntoView({ behavior: 'smooth' });
            this.$emit('pagination', { page: this.page });
        },

        handleLoadMore () {
            this.scrollableElement = getClosestElementWithScroll(this.$el);
            this.prevY = this.scrollableElement?.scrollTop ?? 0;

            if (!this.loading && !this.isLoadingMore && this.total > this.page * this.pageSize) {
                this.currentPage++;
                this.$emit('load-more');
            }
        },

        handleResize (rect) {
            this.$emit('resize', rect);
        },

        handleMouseMove (e) {
            if (this.isDragging) {
                if (Math.abs(e.pageX - this.oldX) >= 30) {
                    if (
                        e.pageX < this.oldX &&
                        this.dragInfo.level > 0 &&
                        !this.dragInfo.nextItem
                    ) {
                        // Налево
                        const parentOfCurrent = this.flatTableData.find(i => i.id === this.dragInfo.parent?.id);

                        if (this.parentChecker({
                            parent: parentOfCurrent?.parent,
                            dragged: this.dragInfo.draggedItems[0],
                            newLevel: this.dragInfo.level - 1
                        })) {
                            this.dragInfo.level--;
                            this.updateDraggableElLevel();
                            this.dragInfo.parent = parentOfCurrent?.parent ?? null;
                            this.dragInfo.prevItem = this.getPrevItemInSameNode(this.dragInfo.newIndex);
                            this.dragInfo.nextItem = this.getNextItemInSameNode(this.dragInfo.newIndex);
                        }
                    } else if (
                        e.pageX > this.oldX &&
                        this.dragInfo.newIndex > 0 &&
                        this.dragInfo.prevItem &&
                        this.parentChecker({
                            parent: this.dragInfo.prevItem,
                            dragged: this.dragInfo.draggedItems[0],
                            newLevel: this.dragInfo.level + 1
                        })
                    ) {
                        // Направо
                        this.dragInfo.level++;
                        this.updateDraggableElLevel();
                        this.$refs.table.toggleRowExpansion(this.dragInfo.prevItem, true);
                        this.dragInfo.parent = this.dragInfo.prevItem;
                        this.dragInfo.prevItem = this.getPrevItemInSameNode(this.dragInfo.newIndex);
                        this.dragInfo.nextItem = this.getNextItemInSameNode(this.dragInfo.newIndex);
                    }
                }

                this.oldX = e.pageX;
            }
        },

        handleMouseDown (e) {
            const el = e.target.closest('.el-table__row');
            this.selectChildren(el);
            this.oldX = e.pageX;
        },

        changeList () {
            this.editDialogVisible = true;
        },

        handleMouseEnterRow (e) {
            if (this.isDragging) {
                const elIndex = e.target.rowIndex;
                const row = this.flatTableData[elIndex - (elIndex > this.dragInfo.newIndex ? 1 : 0)];

                if (elIndex !== this.dragInfo.newIndex && row) {
                    this.hoverRowTimeout = setTimeout(() => {
                        this.toggleRowExpansion(row, true);
                    }, 400);
                }
            }
        },

        handleMouseLeaveRow () {
            clearTimeout(this.hoverRowTimeout);
        },
    },
};
</script>
