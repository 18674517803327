import { getContract, createContract, deleteContract, getContracts, updateContract, startApproving, approve, decline, reject, archive } from '@/api/contract';
import { createCrudModule } from '@/utils/vuex-crud';

const module = createCrudModule({
    businessDependent: true,
    doFetchList (params: object) {
        return getContracts(params);
    },
    doFetchSingle (id: string) {
        return getContract(id);
    },
    doCreate (data: object) {
        return createContract(data);
    },
    doUpdate (id: string, data: object) {
        return updateContract(id, data);
    },
    doDelete (id: string) {
        return deleteContract(id);
    }
});

Object.assign(module.actions, {
    async startApproving ({ commit }, { id, data }: { id: string, data: object }) {
        commit('UPDATE_START');
        try {
            const response = await startApproving(id, data);
            commit('UPDATE_SUCCESS', response);
            return response;
        } catch (error) {
            console.error(error);
            commit('UPDATE_ERROR', error);
            return null;
        }
    },

    async approve ({ commit }, { id, data }: { id: string, data: object }) {
        commit('UPDATE_START');
        try {
            const response = await approve(id, data);
            commit('UPDATE_SUCCESS', response);
            return response;
        } catch (error) {
            console.error(error);
            commit('UPDATE_ERROR', error);
            return null;
        }
    },

    async decline ({ commit }, { id, data }: { id: string, data: object }) {
        commit('UPDATE_START');
        try {
            const response = await decline(id, data);
            commit('UPDATE_SUCCESS', response);
            return response;
        } catch (error) {
            console.error(error);
            commit('UPDATE_ERROR', error);
            return null;
        }
    },

    async reject ({ commit }, { id, data }: { id: string, data: object }) {
        commit('UPDATE_START');
        try {
            const response = await reject(id, data);
            commit('UPDATE_SUCCESS', response);
            return response;
        } catch (error) {
            console.error(error);
            commit('UPDATE_ERROR', error);
            return null;
        }
    },

    async archive ({ commit }, { id, data }: { id: string, data: object }) {
        commit('UPDATE_START');
        try {
            const response = await archive(id, data);
            commit('UPDATE_SUCCESS', response);
            return response;
        } catch (error) {
            console.error(error);
            commit('UPDATE_ERROR', error);
            return null;
        }
    },
});

export default module;
