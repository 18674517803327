<template>
    <div id="app">
        <ui-loading
            v-if="!isBooted"
            height="100vh"
        />
        <component
            :is="layout"
            v-else
            :mode="mode"
        >
            <the-error-page
                v-if="!hasRouteAccess($route)"
                title="Страница не найдена"
                description="Отсутствует страница, или у вас нет доступа к ней"
            />
            <router-view v-else />
        </component>

        <ui-route-drawer v-if="isTopWindow" />
    </div>
</template>

<script>
import { detect as detectBitrix24 } from '@/utils/bitrix24';
import AuthLayout from '@/layouts/Auth';
import HomeLayout from '@/layouts/Home';
import DefaultLayout from '@/layouts/Default';
import DrawerLayout from '@/layouts/Drawer';
import TheErrorPage from '@/components/TheErrorPage';
import RoutePermissionMixin from '@/mixins/route-permission';
import { getTopWindow } from '@/utils';
import 'iframe-resizer/js/iframeResizer.contentWindow';

export default {
    components: {
        AuthLayout,
        HomeLayout,
        DefaultLayout,
        DrawerLayout,
        TheErrorPage,
    },

    mixins: [RoutePermissionMixin],

    data () {
        return {
            booted: true,
        };
    },

    computed: {
        layout () {
            if (this.isIframe) {
                return 'drawer-layout';
            }

            const routeWithLayout = [...this.$route.matched].reverse().find(route => !!route?.meta?.layout);
            return `${(routeWithLayout?.meta?.layout ?? 'default')}-layout`;
        },

        isBooted () {
            return this.booted && this.$store.getters.isBooted;
        },

        isBitrix24 () {
            return detectBitrix24();
        },

        isIframe () {
            return !this.isTopWindow;
        },

        mode () {
            return this.isBitrix24 ? 'bitrix24' : 'default';
        },

        isTopWindow () {
            return window.self === getTopWindow();
        },
    },

    created () {
        if (this.$router.mode === 'abstract') {
            const { pathname, search, hash } = window.location;
            this.$router.replace(pathname + search + hash);
        }

        window.addEventListener('beforeunload', this.handleWindowBeforeUnload);
    },

    mounted () {
        if (this.isBitrix24) {
            this.booted = true;
            // const script = document.createElement('script');
            // script.src = 'https://api.bitrix24.com/api/v1/';
            // script.onload = () => {
            //     BX24.init(() => {
            //         this.booted = true;
            //     });
            // };
            // document.head.appendChild(script);
        }

        if (!this.isTopWindow) {
            document.body.classList.add('is-iframe');
        }
    },

    methods: {
        handleWindowBeforeUnload (event) {
            if (this.$dirty) {
                event.returnValue = this.$t('message.confirmDataIsDirty');
                return event.returnValue;
            }
        },
    },
};
</script>
