<template>
    <el-dropdown
        ref="dropdownSelect"
        v-bind="proxyProps"
        :disabled="disabled"
        class="dropdown-select"
        trigger="click"
        @command="handleCommand"
    >
        <el-button
            type="text"
            :disabled="disabled"
            :size="buttonSize"
            class="dropdown-select__button"
        >
            {{ selectedItem ? selectedItem[labelKey] : value }}
        </el-button>
        <el-dropdown-menu
            slot="dropdown"
            class="dropdown-select__menu"
        >
            <el-dropdown-item
                v-for="item in items"
                :key="item.id"
                :command="item[valueKey]"
                :class="setItemClass(item)"
            >
                {{ item[labelKey] }}
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import WrapperMixin from '@/mixins/wrapper';

export default {
    name: 'UiDropdownSelect',
    mixins: [WrapperMixin],
    props: {
        value: {
            type: String,
            default: '',
        },

        labelKey: {
            type: String,
            default: 'name',
        },

        valueKey: {
            type: String,
            default: 'value',
        },

        items: {
            type: Array,
            default: () => [],
        },

        buttonSize: {
            type: String,
            default: 'small',
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        selectedItem () {
            return this.items.find(item => item[this.valueKey] === this.value) || null;
        },
    },

    methods: {
        handleCommand (command) {
            this.$emit('input', command);
            this.$emit('change', this.selectedItem);
        },

        setItemClass (item) {
            const classes = [];

            if (item[this.valueKey] === this.value) {
                classes.push('selected');
            }

            return classes;
        },
    },
};
</script>

<style lang="scss">
.dropdown-select {

    &__button {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    &__menu.el-dropdown-menu {
        .el-dropdown-menu__item {
            &.selected {
                color: $--color-primary;
                font-weight: bold;
            }
        }
    }
}
</style>
