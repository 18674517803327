import request from '@/utils/request';

const baseUri = '/requisites';

export const getRequisites = (params) =>
    request({
        url: baseUri,
        method: 'GET',
        params,
    });

export const getRequisite = (id) =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
    });
