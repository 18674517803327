<template>
    <el-dropdown
        trigger="click"
        class="business-group-current-select"
        @command="handleCommand"
    >
        <el-button
            type="text"
            class="business-group-current-select__button"
        >
            <span class="business-group-current-select__button-text">
                {{ activeGroup?.name ?? 'Выбрать группу бизнесов' }}
            </span>
            <iconify-icon icon="akar-icons:chevron-down" />
        </el-button>

        <el-dropdown-menu>
            <el-dropdown-item
                v-for="group in groups"
                :key="group.id"
                :command="group.id"
            >
                {{ group.name }}
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import { useStore } from '@/composable/useStore';
import { BusinessGroup } from '@/types/BusinessGroup';
import { useRouter } from 'vue-router/composables';

const store = useStore();
const router = useRouter();

const isLoading = ref(false);
const groups = ref<BusinessGroup[]>([]);

const currentGroup = computed(() => store.getters.currentBusinessGroup);

const activeGroup = computed({
    get() {
        return groups.value.find(i => i.id === store.getters.currentBusinessGroupId) ?? currentGroup.value as BusinessGroup;
    },

    async set(val) {
        isLoading.value = true;
        if (val !== (activeGroup.value as BusinessGroup)?.id) {
            await store.dispatch('updateMe', {
                params: { primaryBusinessGroup: val }
            });
        }
        isLoading.value = false;
    }
});

onBeforeMount(async () => {
    isLoading.value = true;

    const response = await store.dispatch('businessGroup/fetchList', {
        pagination: 0
    });

    groups.value = response['hydra:member'] ?? [];

    isLoading.value = false;
});

function handleCommand(command: string) {
    activeGroup.value = command;
}
</script>

<style lang="scss">
.business-group-current-select {
    &__button {
        font-weight: bold !important;
        color: $--color-text-primary !important;

        & > span {
            display: flex;
            align-items: center;
        }

        .iconify {
            margin-left: 5px;
        }
    }

    &__button-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 195px;
        display: inline-block;
    }
}
</style>
